<template>
    <div class="stages-box">
        <div
            v-for="(day, index) in totalDays"
            :key="`day-${day}`"
            class="stage "
            :class="{active: index < days}"
        >
            DAY {{ index }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        days: {
            type: Number,
            default: 0
        },
        totalDays: {
            type: Number,
            default: 5
        }
    }
}
</script>

<style lang="scss">
.stages-box {
    display: flex;
    height: 50px;
    width: 100%;
    background-color: #E1E8ED;
    margin-bottom: 30px;
    position: relative;

    .stage {
        position: relative;
        height: 100%;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 600;
        box-shadow: inset 0 0 0 0,
                    inset 2px 0 0 0 white,
                    inset 0 0 0 0,
                    inset 0 0 0 0;

        &:first-child {
            box-shadow: inset 0 0 0 0;
        }

        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
        }


        &:before {
            right: -27px;
            border-top: 12px solid transparent;
            border-left: 27px solid white;
            border-bottom: 12px solid transparent;
        }

        &:after {
            right: -23px;
            border-top: 9px solid transparent;
            border-left: 23px solid #E1E8ED;
            border-bottom: 9px solid transparent;
        }

        &.active {
            background-color:  var(--secondary-color);
            color: white;

            &:after {
                border-left-color:  var(--secondary-color);
            }
        }

        &:last-child {
            &:before,
            &:after {
                content: initial;
            }
        }
    }
}
</style>
